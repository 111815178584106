import styles from "./Valentines.module.scss";
import React from "react";

interface ValentinesProps {
  children:any,
  className?: string;
}

const Valentines: React.FC<ValentinesProps> = ({
  children,
  className = "",
}) => {
  return (
    <div className={[styles.Valentines, className].join(" ")}>
      <div className={styles.frame}>
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  );
};

export default Valentines;
