import TagManager from "react-gtm-module";
class EventTracker {
  constructor(gtmId?: string) {
    gtmId && TagManager.initialize({
      gtmId: gtmId,
    });
  }

  track = (event: string, payload?: object) => {
    typeof window !== "undefined" && (window as any)?.dataLayer?.push({ event, ...payload});
  };
}

export default EventTracker;
