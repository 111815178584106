import { Button, InputField, ResetIcon } from "@unbounce/ui-components";
import React from "react";
import SocialShare from "../../components/SocialShare";
import Valentines from "../../components/Greetings/Valentines";
import GreetingHook from "../../hooks/GreetingHook";
import generateBreaks from "../../utils/generateBreaks";
import styles from "./Home.module.scss";
import smartCopyLogo from "../../assets/images/header.png";
import Loader from "../../components/Loader";

interface HomePageProps {
  sampleData?: string[];
}

export const HomePage: React.FC<HomePageProps> = ({ sampleData }) => {
  // const { track } = useEventTraker();
  const [toValue, setToValue] = React.useState("");
  const [topicValue, setTopicValue] = React.useState("");
  const [relationshipValue, setRelationshipValue] = React.useState("");
  const [fromValue, setFromValue] = React.useState("");
  const [sharableLink, setSharableLink] = React.useState("");

  const [aiLoading, aiResponse, getData] = GreetingHook();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    getData(relationshipValue, toValue, topicValue);
  };

  const handleTextChange =
    (stateTarget: (value: string) => void) =>
    ({ currentTarget }: React.FormEvent<HTMLInputElement>) =>
      stateTarget(currentTarget.value);

  const { greetings, id } = aiResponse || {};

  const greetingText = greetings?.valentines[0].text
    ? greetings.valentines[0].text + "\n\n\n\n" + fromValue
    : "";

  React.useEffect(() => {
    aiResponse && setSharableLink(`${window.location.origin}/thank-you/${id}`);
  }, [aiResponse, id]);

  return (
    <div className={styles.homePage}>
      <div className={styles.header}>
        <a href="https://unbounce.com/try-smart-copy/?utm_source=vday23&utm_medium=social"><img src={smartCopyLogo}></img></a>
      </div>
      <main>
        <div className={styles["prompt"]}>
<h1><span className={styles.greentext}>(A)</span> I wrote you a love letter 💙</h1>
<p>Share some love with your customers, colleagues, even your local library with a special message. Just fill in the blanks and AI will take care of the rest. ⬇️</p>
        </div>
        <form onSubmit={handleSubmit} className={styles.inputForm}>
          <p>
            Write a message for{" "}
            <InputField
              max={30}
              data-lpignore="true"
              placeholder="e.g. Emma"
              name={"to"}
              value={toValue}
              onChange={handleTextChange(setToValue)}
            />
            , who is my{" "}
            <InputField
              max={30}
              placeholder="e.g. co-worker"
              name={"relationship"}
              value={relationshipValue}
              onChange={handleTextChange(setRelationshipValue)}
            />{" "}
            and theme it around{" "}
            <InputField
              max={30}
              placeholder="e.g. bagels"
              name={"topic"}
              value={topicValue}
              onChange={handleTextChange(setTopicValue)}
            />
            .
            {/* <br />
          From:{" "}
          <InputField
            placeholder="a secret admirer"
            name={"from"}
            value={fromValue}
            onChange={handleTextChange(setFromValue)}
          />
          . */}
          </p>
          <div className={styles["btn-container"]}>
            {greetingText ? (
              <button className={styles.retryCta} type={"submit"}>
                <ResetIcon />
                Write another Message
              </button>
            ) : (
              <Button primary type={"submit"}>
                <ResetIcon /> Write My Message
              </Button>
            )}
          </div>
        </form>
        {aiLoading ? (
          <Loader></Loader>
        ) : (
          greetingText && (
            <>
              <Valentines className={styles.greeting}>
                {generateBreaks(greetingText)}
              </Valentines>
            </>
          )
        )}
      </main>
      {greetingText && (
        <>
          <SocialShare url={sharableLink} />
        </>
      )}
    </div>
  );
};

export default HomePage;
