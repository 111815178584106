import React, {useRef} from 'react';
import styles from "./styles.module.css";

const CopyText = ({ value, ctaText = "Copy Link" }) => {
    const inputRef = useRef(null);
    const handleClick = () => {
        inputRef.current.select();
        navigator.clipboard.writeText(value);
    }

    return (
        <div className={styles.copyText}>
            <input ref={inputRef} value={value} readOnly />
            <button onClick={handleClick}>{ctaText}</button>
        </div>
    )
}

export default CopyText;
