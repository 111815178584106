import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { EventTrackerContext } from "./providers/eventTracker";
import { EventTracker } from "./providers/eventTracker";
import { configs } from "./configs";
import { BrowserRouter as Router } from "react-router-dom";

const analytics = new EventTracker(configs.gtmId);

ReactDOM.render(
  <React.StrictMode>
    <EventTrackerContext.Provider value={analytics}>
      <Router>
        <App />
      </Router>
    </EventTrackerContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
