import React from "react";
import {
  generateGreeting,
  getGreeting as getGreetingData,
  GreetingResponse,
} from "../../providers/greeting";

const GreetingHook = (): [
  boolean,
  GreetingResponse | undefined,
  (relationship: string, to: string, keyword: string) => void,
  (id: string) => void
] => {
  const [greetingData, setGreeting] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const getGreeting = (id: string) => {
    setLoading(true);
    getGreetingData(id).then((response: any) => {
      if (response) {
        setGreeting(response);
      }
      setLoading(false);
    });
  };
  const createGreeting = (
    relationship: string = "",
    to: string = "",
    keyword: string = ""
  ) => {
    setLoading(true);
    generateGreeting(relationship, to, keyword).then((response: any) => {
      if (response) {
        setGreeting(response);
      }
      setLoading(false);
    });
  };

  return [loading, greetingData, createGreeting, getGreeting];
};

export default GreetingHook;
