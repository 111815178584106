import { configs } from "../../configs";

export interface AIContent {
  text: string;
  index: number;
  finish_reason: string;
}

export interface GreetingResponse {
  id: string;
  greetings: {
    valentines: AIContent[];
  };
}

export const generateGreeting = async (
  relationship: string,
  to: string,
  keyword: string
): Promise<GreetingResponse | undefined> => {
  if (configs.greetingGeneratorApi) {
    try {
      const response = await fetch(configs.greetingGeneratorApi, {
        method: "POST",
        body: JSON.stringify({
          relationship,
          to,
          keyword,
        }),
      });

      if (response.ok) {
        const res = await response.json();
        const id = res.id;
        const greetings = res.data.reduce((result: any, item: any) => {
          const key = Object.keys(item)[0];
          result[key] = item[key];
          return result;
        });

        return {
          id,
          greetings,
        };
      }
    } catch (err) {
      console.log("ERROR");
    }
  }
};

export const getGreeting = async (id: string) => {
  if (configs.greetingGeneratorApi) {
    try {
      const response = await fetch(`${configs.greetingGeneratorApi}/${id}`);

      if (response.ok) {
        const res = await response.json();
        const id = res.id;
        const greetings = res.data.reduce((result: any, item: any) => {
          const key = Object.keys(item)[0];
          result[key] = item[key];
          return result;
        });

        return {
          id,
          greetings,
        };
      }
    } catch (err) {
      console.log("ERROR");
    }
  }
};
