import React from "react";

export const TwitterIcon = ({ "data-testid": dataTestId, className }) => (
  <svg viewBox="0 0 16 16" data-testid={dataTestId} className={className}>
    <g>
      <g>
        <circle cx="8" cy="8" r="8" />
        <path
          fill="#fff"
          d="M11.79,5.72a3.32,3.32,0,0,1-.9.24,1.53,1.53,0,0,0,.69-.85,3.27,3.27,0,0,1-1,.37A1.56,1.56,0,0,0,7.9,6.54a1.37,1.37,0,0,0,0,.36,4.37,4.37,0,0,1-3.2-1.63,1.56,1.56,0,0,0,.48,2.08,1.57,1.57,0,0,1-.71-.19v0A1.55,1.55,0,0,0,5.76,8.7a1.4,1.4,0,0,1-.41.05,1.37,1.37,0,0,1-.29,0A1.56,1.56,0,0,0,6.51,9.81a3.15,3.15,0,0,1-1.93.66,2.23,2.23,0,0,1-.37,0,4.32,4.32,0,0,0,2.38.7A4.4,4.4,0,0,0,11,6.73c0-.07,0-.14,0-.2A3.16,3.16,0,0,0,11.79,5.72Z"
        />
      </g>
    </g>
  </svg>
);
