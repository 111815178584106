import React from "react";
import styles from "./App.module.scss";
import "./styles/base.scss";
import { Switch, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import ValentinesCard from "./pages/ValentinesCard";
import { ButtonLink } from "@unbounce/ui-components";

const App: React.FC = () => {
  return (
    <>
      <div className={styles.App}>
        <Switch>
          <Route path="/thank-you/:id" component={ValentinesCard} />
          <Route path="/" component={HomePage} />
        </Switch>
      </div>
      <footer className={styles.footer}>
        <hr className={styles.hr} />
        <h2>Instantly write anything with Smart Copy</h2>
<p>Why waste time on writing copy for blogs, ads, or even landing pages? <br/> With Smart Copy, everyone is a copywriter. And it's included in your Unbounce dashboard for 2023!</p>
        <ButtonLink
          href={
            "https://app.unbounce.com/ai-copy"
          }
          className={styles["footer-btn"]}
        >
          Log in and explore Smart Copy
        </ButtonLink>
        <p style={{ fontSize: 'medium' }}><i>P.S. Not an Unbounce customer? Why hello, friend! <a href="https://unbounce.com/pricing/" target={"_blank"}>Join us for a 14-day trial</a> to try out Smart Copy and our AI-powered landing page builder.</i></p>
      </footer>
    </>
  );
};
export default App;
