import React from "react";
import { RouteComponentProps } from "react-router";
import HomePage from "./Home";

export const HomePageContainer: React.FC<RouteComponentProps> = (props) => {
  return (
      <HomePage />
  );
};

export default HomePageContainer;
