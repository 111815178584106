const generateBreaks = (content: string) => {
  return content.split("\n\n").map(
    (paragraph) =>
      paragraph && (
        <p>
          {paragraph.split("\n").map((line) => (
            <>
              {line}<br />
            </>
          ))}
        </p>
      )
  );
};

export default generateBreaks;
