import React from "react";
import EventTracker from "./eventTracker";

interface EventTrackerProps {
    track: (event: string, payload?: object) => any;
}

const EventTrackerContext = React.createContext<EventTrackerProps>({
    track: () => void(0),
});

const useEventTraker = () => React.useContext(EventTrackerContext);

export {
    EventTrackerContext,
    EventTracker,
    useEventTraker,
}
