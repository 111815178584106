import { ButtonLink } from "@unbounce/ui-components";
import React from "react";
import Valentines from "../../components/Greetings/Valentines";
import generateBreaks from "../../utils/generateBreaks";
import styles from "./Valentines.module.scss";
import smartCopyLogo from "../../assets/images/header.png";


interface GreetingProps {
  text: string;
}

export const GreetingPage: React.FC<GreetingProps> = ({ text }) => {
  return (
    <div className={styles.valentinesPage}>
       <div className={styles.header}>
       <a href="https://unbounce.com/try-smart-copy/?utm_source=vday23&utm_medium=social"> <img src={smartCopyLogo}></img></a>
      </div>
      <main>
          <p className={styles.message}>
          <span className={styles.greentext}>(A)</span>I wrote this especially for you:
          </p>
          <Valentines>{generateBreaks(text)}</Valentines>
      <ButtonLink className={styles.cta} href={"/"}>Create Your Own Message</ButtonLink>
     </main>

    </div>
  );
};

export default GreetingPage;
