import React from 'react';
import styles from "./styles.module.css";
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";

import { FacebookIcon, TwitterIcon } from './icons/';

const ShareLinks = ({ url = window.location.href, quote, className = "" }) => {

    const componentMap = [
        {
            Link: FacebookShareButton,
            Icon: FacebookIcon,
            props: {
                quote,
            }
        },
        {
            Link: TwitterShareButton,
            Icon: TwitterIcon,
            props: {
                title: quote,
            }
        }
    ];

    return (
        <ul className={[styles.socialLinks, className].join(" ")}>
            {componentMap.map(({Link, Icon, props}, index) => (
                <li key={"social-" + index}>
                    <Link url={url} {...props}>
                        <Icon className={styles.icon} />
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default ShareLinks;
