import React from 'react'
import styles from './Loader.module.scss'
function Loader() {
    return (
        <div className={styles["container"]}>
            <div className={[styles.base, styles.primary].join(" ")}>
                <div className={[styles.part, styles.left].join(" ")}></div>
                <div className={[styles.part, styles.right].join(" ")}></div>
            </div>
            <div className={[styles.base, styles.secondary].join(" ")}>
                <div className={[styles.part, styles.left].join(" ")}></div>
                <div className={[styles.part, styles.right].join(" ")}></div>
            </div>
            <div className={[styles.base, styles.tertiary].join(" ")}>
                <div className={[styles.part, styles.left].join(" ")}></div>
                <div className={[styles.part, styles.right].join(" ")}></div>
            </div>
        </div>
    )
}

export default Loader;
