import React from "react";
import styles from "./styles.module.css";
import ShareLinks from "./components/ShareLinks";
import CopyText from "./components/CopyText";

export const SocialShare: React.FC<{ url: string }> = ({ url }) => {
  const quote =
    "(A)I wrote this love letter especially for you. 😍 Create your own message in seconds to share the love. Xoxo @unbounce ➡️";

  return (
    <div className={styles.socialShare}>
      <ShareLinks url={url} quote={quote.replace(/(<([^>]+)>)/gi, "")} />
      <p>
        <small>
        Click the social icon(s) to share your message or copy the link and send it directly.
        </small>
      </p>
      <CopyText value={url} />
    </div>
  );
};

export default SocialShare;
