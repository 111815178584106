import React from "react";
import { RouteComponentProps, useParams, useHistory } from "react-router";
import Greeting from "./ValentinesCard";
import {
  getGreeting as getGreetingData,
} from "../../providers/greeting";
import Loader from "../../components/Loader";

export const HomePageContainer: React.FC<RouteComponentProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const history =  useHistory();
  const [greeting, setGreeting] = React.useState("");

  React.useEffect(() => {
    if (!greeting) {
      getGreetingData(id).then((response) => {
        if (response) {
          setGreeting(response?.greetings?.valentines[0].text || "");
        } else {
          history.push("/");
        }
      });
    }
  }, [id, greeting]);
  return <>{!greeting ? <Loader /> : <Greeting text={greeting} />}</>;
};

export default HomePageContainer;
