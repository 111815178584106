import React from "react";

export const FacebookIcon = ({ "data-testid": dataTestId, className }) => (
  <svg viewBox="0 0 16 16" data-testid={dataTestId} className={className}>
    <g>
      <g>
        <circle className="cls-1" cx="8" cy="8" r="8" />
        <path
          fill="#fff"
          d="M7.1,8.4v3.39H8.75V8.42H9.87L10.15,7H8.76V6a.31.31,0,0,1,.31-.34h.8V4.21H8.37A1.49,1.49,0,0,0,7.1,5.52V7H6.23V8.39Z"
        />
      </g>
    </g>
  </svg>
);
